











import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private url: any = "";
  private mounted() {
    this.url = this.$route.query.url;
  }
}
